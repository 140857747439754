import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './Tribute.css';
import Layout from '../../Common/Layout';
import { Person } from 'react-ionicons';
import { deleteComment, getTributeByCode, saveComment } from '../../../service/serviceHelper';
import { useAuth } from '../../../context/AuthContext';
import { TextField } from '@mui/material';
import Notification from '../../Common/Notification';
import LoadingForButton from '../../Common/LoadingForButton';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../helper';

export default function Tribute() {
  const { t } = useTranslation();
  const { userCode } = useParams();
  const { authState } = useAuth();
  const [role, setRole] = useState('Public');
  const [owner, setOwner] = useState(false);
  const [data, setData] = useState({
    firstName: authState?.user?.firstName || "",
    lastName: authState?.user?.lastName || "",
    comment: ""
  })
  const [loading, setLoading] = useState(true);
  const [tribute, setTribute] = useState(null);
  const [validationError, setValidationError] = useState(false);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [saving, setSaving] = useState(false);


  const validateTribute = () => {
    if (
      !data?.comment ||
      !data?.firstName ||
      !data?.lastName    ) {
      setValidationError(t("all field is required"));
      return false;
    }
    setValidationError('');
    setData({
      firstName: authState?.user?.firstName || "",
      lastName: authState?.user?.lastName || "",
      comment: ""
    });
    return true;
  };

  const handleCancel = () => {
    setValidationError("");
    setData({
      firstName: authState?.user?.firstName || "",
      lastName: authState?.user?.lastName || "",
      comment: ""
    });
    setEditing(false);
  }
  
  const handleEditing = () => {
    if (editing) {
      if (!validateTribute()) {
        return;
      }
      // Save tribute changes here if needed
      handleSave();
    }
    setEditing(!editing);
  }

  const handleAddTribute = () => {
    // if (role === 'Public') {
    //   navigate('/signin');
    // } else {
      handleEditing();
    // }
  }

  const handleSave = async () => {
    setSaving(true);
    const sendComment = async () => {
    const result = await saveComment(tribute, data, authState?.user, userCode);
      if (result.error) {
        setError(t("an error occurred, please try again"))
        setSaving(false);
      } else {
        setSuccess(t("saved successful"));
        setSaving(false);
        fetchTribute();
      }
    }
    sendComment();
  }

  const handleRemove = async (id) => {
    setSaving(true);
    const result = await deleteComment(id);      
    if (result.error) {
      setError(t("an error occurred, please try again"))
      setSaving(false);
    } else {
      setSuccess(t("delete successful"));
      setSaving(false);
      fetchTribute();
    }
    setSaving(false);
  }

  const fetchTribute = async () => {
    const result = await getTributeByCode(userCode);
    // console.log('the tribute result - ', result?.data[0]?.attributes?.tribute?.data?.attributes?.comments?.data)
    if (result.error) {
        setError(true);
    }

    if (authState?.authenticated === true) {
      setRole(authState?.user?.role?.name)
    }
    // console.log('the comment - ', result?.data[0]?.attributes?.comments?.data)
    if (!result.error) {
      // console.log('the things - --- ', result)
      setTribute({
        "id": result.userId,
        "comment": result.comments,
        "ownerId": result.ownerId,
        "ownerUserCodeId": result.ownerUserCodeId
      })
    }

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchTribute();
  }, [authState])

  useEffect(() => {
    // console.log('the tahingae - ', authState?.user?.role?.name, authState?.user?.user_code?.slug, userCode)
    if (authState?.user?.user_code?.slug === userCode) {
      setOwner(true);
    }
  }, [authState, userCode])

  return (
    <Layout userCode={userCode} loading={loading} setLoading={setLoading}>
      { owner === true ? null : 
        <div className='button-edit'>
          {editing && !saving ? <button onClick={handleCancel} className='tribute-add'>{t("cancel")}</button> : null}
          {saving ? <button className='tribute-add'><LoadingForButton /></button> : <button onClick={handleAddTribute} className='tribute-add'>{editing === false ? t("tribute.addTribute") : t("save")}</button>}
        </div>
      }
      
       {
        editing ? 
        <TributeEdit 
          data={data} 
          setData={setData}
          authState={authState}
          validationError={validationError} 
          setValidationError={setValidationError}
          success={success}
          setSuccess={setSuccess}
        />
        :
        <TributeCurrent 
          tribute={tribute} 
          userCode={userCode}
          authState={authState}
          saving={saving}
          handleRemove={handleRemove} 
        />
      }
      {validationError && (
        <Notification
        type='error'
        message={validationError}
        duration={3000}
        onClose={() => setValidationError(null)}
        />
      )}

      {error && (
        <Notification
        type='error'
        message={error}
        duration={3000}
        onClose={() => setError(null)}
        />
      )}

      {success && (
        <Notification
        type='success'
        message={success}
        duration={3000}
        onClose={() => setSuccess(null)}
        />
      )}

    </Layout>
  );
}


const TributeEdit = ({
  data, 
  setData,
  authState
}) => { 

  const { t } = useTranslation();
  const handleChange = (e) => {
    const {name, value} = e.target;

    setData({
      ...data,
      [name]: value
    })
  }

  return (
    <div>
      <div className='tribute-edit'>
        <div className='tribute-labels'>
          {
            authState?.authenticated ? null :
            <>
              <TextField 
                label={t("auth.firstName")} 
                type="text"
                name="firstName"
                value={data.firstName}
                onChange={handleChange}
                required
                variant="outlined" 
                InputLabelProps={{ shrink: true }}
              />

              <TextField 
                label={t("auth.lastName")} 
                type="text"
                name="lastName"
                value={data.lastName}
                onChange={handleChange}
                required
                variant="outlined" 
                InputLabelProps={{ shrink: true }}
              />
            </>
          }
          <TextField
            label={t("tribute.comment")}
            multiline
            rows={5}
            name="comment"
            value={data.comment}
            onChange={handleChange}
            required
            variant="outlined" 
            InputLabelProps={{ shrink: true }}
          />
        </div>

      </div>
    </div>
  )
}


const TributeCurrent = ({
  tribute,
  userCode,
  authState,
  saving,
  handleRemove
}) => {
  useEffect(() => {
  }, [tribute])

  const { t } = useTranslation();
  const [sortedTribute, setSortedTribute] = useState([]);

  useEffect(() => {
    if (tribute?.comment && tribute?.comment.length > 0) {
      const sorted = [...tribute?.comment].sort((a, b) => {
        return new Date(a.attributes.createdAt) - new Date(b.attributes.createdAt);
      });
      setSortedTribute(sorted);
    }
  }, [tribute]);

  // const formatDateForTimeline = (dateString) => {
  //   const options = { year: 'numeric', month: 'short', day: 'numeric' };
  //   return new Date(dateString).toLocaleDateString(undefined, options);
  // };

  return (
    <div className='tribute'>
        {/* {console.log('the tribute itself ', tribute, sortedTribute)} */}
       {tribute?.id !== undefined && sortedTribute !== undefined ?
        sortedTribute.map((comm, index) => {
          const userAttributes = comm?.attributes;
          const { firstName, lastName, id } = userAttributes || {};
          
          return (
            <div key={index} className='tribute-comments'>
              <div className='bio-container'>
                <div className='avatar'>
                  <Person style={{
                    height: '18px',
                    width: '18px',
                    color: '#3C3C3C',
                    fill: '#3C3C3C'
                  }} />
                </div>
                <div>
                  <h3>{capitalizeFirstLetter(firstName)} {capitalizeFirstLetter(lastName)}</h3>
                  <p className='t-date'>{new Date(comm?.attributes?.updatedAt).toLocaleString()}</p>
                </div>
                
                {userCode === authState?.user?.user_code?.slug ? saving ? <button className='tribute-remove'><LoadingForButton /></button> : <button onClick={(e) => handleRemove(comm.id)} className='tribute-remove'>{t("remove")}</button> : null}
                {/* <button onClick={(e) => handleRemove(comm.id)} className='tribute-remove'>Remove</button> */}
              </div>
              <p className='t-comment'>{comm?.attributes?.content}</p>
            </div>
          )
        })
        :
        <div className='tribute'>
          <p className='tribute-empty'>{t("tribute.noCommentYet")}</p>
        </div>
      }
    </div>
  )
}
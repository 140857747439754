import React, { useState, useEffect } from 'react';
import './Notification.css';

const Notification = ({ type, message, duration = 3000, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <div className={`notification ${isVisible ? (type === 'success' ? 'show success' : type === 'error' ? 'show error' : type === 'warning' ? 'show warning' : '') : ''}`}>
      <p>{message}</p>
    </div>
  );
};

export default Notification;
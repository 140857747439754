import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import axios from 'axios';
// import { useAuth } from '../../../context/AuthContext';
import '../SignIn/SignIn.css';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingForButton from '../../Common/LoadingForButton';
import { RESET_PASSWORD } from '../../../service/api';
import Notification from '../../Common/Notification';
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
    const { t } = useTranslation();
    const { token } = useParams(); // Assuming the reset token is in the URL
    // const { onLogin, authState } = useAuth();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        token,
        password: "",
        confirmPassword: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value
        });
    };

    const handleReset = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);
        setLoading(true);

        const { password, confirmPassword } = userData;

        // Validate passwords
        if (password !== confirmPassword) {
            setError(t('auth.passMismatch'));
            setLoading(false);
            return;
        }

        if (password.length < 6) {
            setError(t('auth.minPassLength'));
            setLoading(false);
            return;
        }

        try {
            await axios.post(RESET_PASSWORD, {
                code: token,
                password,
                passwordConfirmation: confirmPassword,
            });
            setSuccess(t('auth.passResetSuccess'));
            setTimeout(() => {
                navigate('/signin');
            }, 3000);

        } catch (err) {
            setError(t('an error occurred, please try again'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className='page'>
            <div className='container'>
                <h1>Reset Password</h1>
                <form onSubmit={handleReset}>
                    <TextField 
                        id="password" 
                        label="New Password" 
                        type="password"
                        name="password"
                        value={userData.password}
                        onChange={handleChange}
                        required
                        variant="outlined" 
                    />
                    <TextField 
                        id="confirmPassword" 
                        label="Confirm Password" 
                        type="password"
                        name="confirmPassword"
                        value={userData.confirmPassword}
                        onChange={handleChange}
                        required
                        variant="outlined" 
                    />

                    {error && (
                        <Notification
                        type='error'
                        message={error}
                        duration={3000}
                        onClose={() => setError(null)}
                        />
                    )}
                    {success && (
                        <Notification
                        type='success'
                        message={success}
                        duration={3000}
                        onClose={() => setSuccess(null)}
                        />
                    )}

                    <button type="submit" disabled={loading}>
                        {loading ? <LoadingForButton /> : t('auth.reset')}
                    </button>
                </form>
            </div>
        </section>
    );
};

export default ResetPassword;

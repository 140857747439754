import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import TextField from '@mui/material/TextField';
import { useAuth } from '../../../context/AuthContext';
import '../SignIn/SignIn.css';
import { Link, useNavigate } from 'react-router-dom';
import LoadingForButton from '../../Common/LoadingForButton';
import Notification from '../../Common/Notification';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../../language/i18n';
import Loading from '../../Common/Loading';


const SignUp = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const { onSignup } = useAuth();
    const [loading, setLoading] = useState(true);
    const [automated, setAutomated] = useState(true);
    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: ""
    });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value
        });
    };

    const validateInputs = (userData) => {
        const { firstName, lastName, email, password, confirmPassword } = userData;
        if (!firstName) return "First Name is required";
        if (!lastName) return "Last Name is required";
        if (!email) return "Email is required";
        if (!/\S+@\S+\.\S+/.test(email)) return "Email is invalid";
        if (!password) return "Password is required";
        if (password.length < 8) return "Password must be at least 8 characters long";
        if (password !== confirmPassword) return "Passwords do not match";
        return null;
    };

    const signUpLogic = async (userData) => {
        setError(null);
        const validationError = validateInputs(userData);
        // console.log('the validataion - ', validationError, userData)
        if (validationError) {
            setError(validationError);
            return;
        }

        // console.log('THE NAMES - ', userData)
        try {
            const result = await onSignup({
                ...userData,
                email: userData.email.toLowerCase(),
                username: userData.email.toLowerCase()
            });
            if (result?.error) {
                // If any problem occur whatsoever, navigate to sign in
                // An error can also be if; email is already registered
                if (automated === true) {
                    navigate('/signin');
                    setAutomated(false);
                } else {
                    setError(result.msg);
                }
            } else {
                setSuccess(t("auth.signUpSuccessful"));
                setTimeout(() => {
                    navigate('/');
                }, 1000);
            }
        } catch (error) {
            // If any problem occur whatsoever, navigate to sign in
            // An error can also be if; email is already registered
            if (automated === true) {
                navigate('/signin');
                setAutomated(false);
            } else {
                setError('An error occurred during signup');
            }
        } finally {
            setLoading(false);
        }
    }

    const handleSignUp = (e, userData) => {
        e.preventDefault();
        setLoading(true);
        signUpLogic(userData);
    };

    useEffect(() => {
        const { firstName, lastName, email } = queryString.parse(location.search); // Extract parameters from query
        if (firstName !== undefined &&
            lastName !== undefined &&
            email !== undefined &&
            automated === true
        ) {
            console.log('another')
            const data = {
                firstName, lastName, email,
                password: "Memorialtag",
                confirmPassword: "Memorialtag"
            }
            signUpLogic(data);
        } else {
            setLoading(false);
            setAutomated(false);
        }
    }, []);

    
    if (automated) {
        return (
            <section className='page'>
                <div className='container'>
                    <Loading />
                </div>
            </section>
        )
    } else {
        return (
            <section className='page'>
                <div className='container'>
                    <h1>Sign Up</h1>
                    <form onSubmit={(e) => handleSignUp(e, userData)}>
                        <TextField
                            id="firstName"
                            label={t("auth.firstName")}
                            type="text"
                            name="firstName"
                            value={userData.firstName}
                            onChange={handleChange}
                            required
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            id="lastName"
                            label={t("auth.lastName")}
                            type="text"
                            name="lastName"
                            value={userData.lastName}
                            onChange={handleChange}
                            required
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            id="email"
                            label={t("auth.email")}
                            type="email"
                            name="email"
                            value={userData.email}
                            onChange={handleChange}
                            required
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            id="password"
                            label={t("auth.password")}
                            type="password"
                            name="password"
                            value={userData.password}
                            onChange={handleChange}
                            required
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            id="confirmPassword"
                            label={t("auth.confirmPassword")}
                            type="password"
                            name="confirmPassword"
                            value={userData.confirmPassword}
                            onChange={handleChange}
                            required
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />

                        {error && (
                            <Notification
                            type='error'
                            message={error}
                            duration={3000}
                            onClose={() => setError(null)}
                            />
                        )}
                        {success && (
                            <Notification
                            type='success'
                            message={success}
                            duration={3000}
                            onClose={() => setSuccess(null)}
                            />
                        )}

                        <button type="submit" disabled={loading}>
                            {loading ? <LoadingForButton /> : t("auth.signUp")}
                        </button>

                        <div className='change-mode'>
                            <p>{t("Already have an account?")}</p>
                            <Link to='/signin'>{t("auth.signIn")}</Link>
                        </div>
                    </form>
                </div>
            </section>
        );
    }
};

export default SignUp;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFound.css';
import { useAuth } from '../../../context/AuthContext';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { authState } = useAuth();
    const [authenticated, setAuthenticated] = useState(false);
  
    useEffect(() => {
      if (authState?.authenticated === true) {
        setAuthenticated(true)
      }
    }, [authState])

    const handleReturn = () => {
        // If login, redirect to / - dashboard
        // If not, redirect to sign up page
        if (authenticated === true) {
            navigate('/');
        } else {
            navigate('/signin');
        }
    }
  return (
    <div className='not-found'>
        <div className='container'>
            <h1>{t("not found")} :&#40;</h1>
            <p>{t("page not found")}</p>
            <button onClick={handleReturn}>{t("return")}</button>
        </div>
    </div>
  );
}

export default NotFound;
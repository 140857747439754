import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    debug: false,
    fallbackLgn: 'en',

    resources: {
        en: {
          translation: {
            auth: {
              email: 'Email',
              password: 'Password',
              username: 'Username',
              firstName: 'First Name',
              lastName: 'Last Name',
              confirmPassword: 'Confirm Password',
              signIn: 'Sign In',
              signUp: 'Sign Up',
              send: 'Send',
              signInSuccessful: 'Sign in successful!',
              signUpSuccessful: 'Sign up successful!',
              
              reset: 'Reset',
              checkYourInbox: 'Please, check your inbox!',
              passMismatch: 'Passwords do not match',
              minPassLength: 'Password must be more than 8 characters',
              passResetSuccess: 'Password reset successful!',
              invalid: 'Invalid email address or password',
              failed: 'Login failed'
            },
            biography: {
              text: 'Biography',
              birthday: 'Birthday',
              deathday: 'Death day',
              content: 'Content',
              addBiographyDetailsFirst: "Add biography details first!",
              noBiographyAdded: 'No biography added!',
            },
            timeline: {
              text: 'Timeline',
              date: 'Date',
              event: 'Event',
              addNew: 'Add a new Timeline',
              noneAdded: 'No Timeline added'
            },
            media: {
              text: 'Media',
              editMode: 'Edit mode',
              liveMode: 'Live mode',
              media: 'Media',
              noImagesAdded: 'No images added!',
              errorFetchingMedia: 'Error fetching media!',
              noVideoAdded: 'No video added!',
              noLinkAdded: 'No link added!',
              addYoutubeLinks: 'Add YouTube links!',
              maxImagesExceeded: 'Upload > 25MB, please, be patient!',
              onlyImageFilesAllowed: 'Only images are allowed!',
              repositionSuccess: 'Reposition successful!',
            },
            tribute: {
              text: 'Tribute',
              comment: 'Comment',
              noTributeAdded: 'No tribute added!',
              addTribute: 'Add tribute',
              noCommentYet: 'No comment yet!',
            },
            nav: {
              myAccount: 'My Account',
              visitStore: 'Visit Store',
              contactUs: 'Contact Us',
              logout: 'Logout'
            },
            'new': 'New',
            'remove': 'Remove',
            'save': 'Save',
            'edit': 'Edit',
            'cancel': 'Cancel',
            "Don't have an account?": "Don't have an account?",
            'Already have an account?': 'Already have an account?',
            'Forgot Password': 'Forgot Password',
            'Continue with Google': 'Continue with Google',
            'your memorial tag id': 'Your Memorial Tag Id',
            'no memorial tag': 'You do not own a Memorial Tag yet. Please buy one',
            'buy now': 'Buy Now',
            'saved successful': 'Saved successful!',
            'an error occurred, please try again': 'An error occurred, please try again!',
            'error saving picture': "Error saving picture!",
            "all fields are required": "All fields are required!",
            'delete successful': 'Delete successful!',

            
            // Reload && Not found
            'return': 'Return',
            'reload': 'Reload',
            'back': 'Back',
            'not found': 'Not found',
            "page not found": 'The page you are browsing does not exist'
          }
        },

        nl: {
          translation: {
            auth: {
              email: 'E-mail',
              password: 'Wachtwoord',
              username: 'Gebruikersnaam',
              firstName: 'Voornaam',
              lastName: 'Achternaam',
              confirmPassword: 'Bevestig wachtwoord',
              signIn: 'Inloggen',
              signUp: 'Aanmelden',
              send: 'Versturen',
              signInSuccessful: 'Inloggen gelukt!',
              signUpSuccessful: 'Aanmelden gelukt!',
              
              reset: 'Resetten',
              checkYourInbox: 'Controleer, alstublieft uw inbox!',
              passMismatch: 'Wachtwoorden komen niet overeen',
              minPassLength: 'Wachtwoord moet meer dan 8 tekens bevatten',
              passResetSuccess: 'Wachtwoord succesvol gereset!',
              invalid: 'Verkeerd e-mail adres of wachtwoord',
              failed: 'Inloggen mislukt'
            },
            biography: {
              text: 'Biografie',
              birthday: 'Geboortedatum',
              deathday: 'Overlijdensdatum',
              content: 'Inhoud',
              addBiographyDetailsFirst: "Voeg eerst biografische gegevens toe!",
              noBiographyAdded: 'Geen biografie toegevoegd!',
            },
            timeline: {
              text: 'Tijdlijn',
              date: 'Datum',
              event: 'Gebeurtenis',
              addNew: 'Nieuwe tijdlijn toevoegen',
              noneAdded: 'Geen tijdlijn toegevoegd'
            },
            media: {
              text: 'Media',
              editMode: 'Bewerkmodus',
              liveMode: 'Livemodus',
              media: 'Media',
              noImagesAdded: 'Geen afbeeldingen toegevoegd!',
              errorFetchingMedia: 'Error',
              noVideoAdded: 'Geen video toegevoegd!',
              noLinkAdded: 'Geen link toegevoegd!',
              addYoutubeLinks: 'YouTube-links toevoegen!',
              maxImagesExceeded: 'Upload > 25MB, alstublieft, wees geduldig!',
              onlyImageFilesAllowed: 'Alleen afbeeldingen zijn toegestaan!',
              repositionSuccess: 'Positie succesvol gewijzigd!',
            },
            tribute: {
              text: 'Steunbetuiging',
              comment: 'Reactie',
              noTributeAdded: 'Geen condoleances toegevoegd!',
              addTribute: 'Condoleance toevoegen',
              noCommentYet: 'Nog geen reactie!',
            },
            nav: {
              myAccount: 'Mijn account',
              visitStore: 'Bezoek winkel',
              contactUs: 'Contacteer ons',
              logout: 'Uitloggen'
            },
            'new': 'Nieuw',
            'remove': 'Verwijderen',
            'save': 'Opslaan',
            'edit': 'Bewerken',
            'cancel': 'Annuleren',
            "Don't have an account?": 'Geen account?',
            'Already have an account?': 'Al een account?',
            'Forgot Password': 'Wachtwoord vergeten',
            'Continue with Google': 'Doorgaan met Google',
            'your memorial tag id': 'Uw MemorialTag-ID',
            'no memorial tag': 'U bezit nog geen Herdenkingslabel. Koop er een',
            'buy now': 'Nu kopen',
            'saved successful': 'Opslaan gelukt!',
            'an error occurred, please try again': 'Er is een fout opgetreden, probeer het opnieuw!',
            "error saving picture": "Fout bij het opslaan van de afbeelding!",
            "all fields are required": "Alle velden zijn verplicht!",
            'delete successful': 'Verwijderen gelukt!',
            
            // Reload && Not found
            'return': 'Terug',
            'reload': 'Herladen',
            'back': 'Terug',
            'not found': 'Niet gevonden',
            'page not found': 'De pagina die u bekijkt bestaat niet'
          }
        }
    }
      
})



export const Translations = {
    // Authentication
    'reset password': ['Wachtwoord resetten'],
    'reset': ['Resetten'],

    // Pages

    'something went wrong': ['Er is iets misgegaan'],
    'try reloading the page': ['Probeer de pagina te herladen'],

}
import React, { useEffect, useState } from 'react';
import { 
  PersonCircleOutline,
  PersonCircle,
  CalendarOutline,
  Calendar,
  Images,
  ImagesOutline,
  Book,
  BookOutline
} from 'react-ionicons';

import './Tabs.css';
import { Link, useLocation, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import '../../../language/i18n';

export default function Tabs() {
  const { t } = useTranslation();
  const { userCode } = useParams();
  const location = useLocation();
  const [routeName, setRouteName] = useState();
  
  useEffect(() => {
    setRouteName(location.pathname.split('/')[2]);
  }, [location.pathname]);

  const tabsIconStyle = {
    width: '28px',
    height: '28px',
    color: '#2B2B2B',
    fill: '#2B2B2B',
    cursor: 'pointer'
  }

  const tabsIconActiveStyle = {
    width: '28px',
    height: '28px',
    color: '#D6C7A1',
    fill: '#D6C7A1',
    cursor: 'pointer'
  }

  return (
    <section className='tabs'>
      <div className='tabs-container'>
        <Link className='tabs-nav' to={`/${userCode}`}>
          {
            routeName === undefined ?
            <>
              <PersonCircle style={tabsIconActiveStyle} />
              <span className='active'>{t("biography.text")}</span>
            </>
            :
            <>
              <PersonCircleOutline
                style={tabsIconStyle}
              />
              <span>{t("biography.text")}</span>
            </>
          }
        </Link>

        <Link className='tabs-nav' to={`/${userCode}/timeline`}>
          {
            routeName === "timeline" ?
            <>
              <Calendar style={tabsIconActiveStyle} />
              <span className='active'>{t("timeline.text")}</span>
            </>
            :
            <>
              <CalendarOutline
                style={tabsIconStyle}
              />
              <span>{t("timeline.text")}</span>
            </>
          }
        </Link>

        <Link className='tabs-nav' to={`/${userCode}/media`}>
          {
            routeName === "media" ?
            <>
              <Images style={tabsIconActiveStyle} />
              <span className='active'>{t("media.text")}</span>
            </>
            :
            <>
            <ImagesOutline
              style={tabsIconStyle}
            />
            <span>{t("media.text")}</span>
            </>
          }
        </Link>

        <Link className='tabs-nav' to={`/${userCode}/tribute`}>
          {
            routeName === "tribute" ?
            <>
              <Book style={tabsIconActiveStyle} />
              <span className='active'>{t("tribute.text")}</span>
            </>
            :
            <>
            <BookOutline
              style={tabsIconStyle}
            />
            <span>{t("tribute.text")}</span>
            </>
          }
        </Link>
      </div>
    </section>
  );
}

import AWS from 'aws-sdk';
import axios from 'axios';
import { nanoid } from 'nanoid';

import { SAVE_BIOGRAPHY, SAVE_BIOGRAPHY_BY_ID, SAVE_MEDIA, SAVE_MEDIA_VIDE0_BY_ID } from './api';

// Set up your AWS credentials
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
});

const s3 = new AWS.S3();
  

export const uploadFile = async (id, files, user, media) => {
  // Generate filenames and create upload parameters for each file
  const uploadPromises = files.map(async (file) => {
      const fileName = generateNanoIDFilename(file, user, "media");
      const params = {
          Bucket: process.env.REACT_APP_S3_BUCKET,
          Key: `media/${fileName}`,
          Body: file,
          ContentType: file.type,
      };

      // Return the S3 upload promise
      await s3.upload(params).promise();
    return fileName;
  });

  try {
      // Wait for all uploads to complete
      const uploadedFileNames = await Promise.all(uploadPromises);
      
      const imageData = {
          "images": [
              ...(media?.images || []),
              ...uploadedFileNames.map((fileName) => ({ "url": fileName })),
          ]
      };

      let url;
      let result;

      if (id) {
          // Update media using PUT
          url = SAVE_MEDIA_VIDE0_BY_ID(id);
          result = await axios.put(url, { "data": { ...imageData } });
      } else {
          // Save new media using POST
          url = SAVE_MEDIA;
          result = await axios.post(url, {
              "data": {
                  ...imageData,
                  "users_permissions_user": user?.id,
                  "user_code": user?.user_code?.id
              }
          });
      }

      return result.data;
  } catch (err) {
      return {
          error: true,
          msg: err?.message || 'Saving Images Failed'
      };
  }
};

export const removeFile = async (id, index, media) => {
    const s3Params = {
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: `media/${media?.images[index]["url"]}`, // The name of the file to delete
    };
  
    try {
        const imageData = {
          "images": media?.images.filter((_, i) => i !== index)
        };
          const url = SAVE_MEDIA_VIDE0_BY_ID(media?.id);
          const result = await axios.put(url, { "data": { ...imageData } });

        await s3.deleteObject(s3Params).promise();

        return { success: true, data: result.data };
    } catch (error) {
      return {
        error: true,
        msg: error?.message || 'Failed to remove file'
      };
    }
};


export const uploadProfileImage = async (type, file, bio, user) => {
    const fileName = generateNanoIDFilename(file, user, type);
    const uploadParams = {
      Bucket: process.env.REACT_APP_S3_BUCKET,
      Key: `${type}/${fileName}`,
      Body: file,
      ContentType: file.type,
    };

    const deleteOldParams = {
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: `${type}/${bio[type]}`, // The name of the file to delete
    };

    try {
      const uploadedImage = await s3.upload(uploadParams).promise();

    //   DELETE THE PREVIOUS IF IT EXISTS
      const bioData = {
        ...bio,
        [type]: fileName
      };
  
      let url;
      let result;
      if (bio?.id) {
        // Update bio using PUT
        url = SAVE_BIOGRAPHY_BY_ID(bio?.id);
        result = await axios.put(url, { "data": { ...bioData } });
      } else {
        // Save new bio using POST
        url = SAVE_BIOGRAPHY;
        result = await axios.post(url, {
          "data": {
            [type]: uploadedImage.Key,
            "users_permissions_user": user?.id,
            "user_code": user?.user_code?.id
          }
        });
      }

      if (bio[type] !== null) {
        await s3.deleteObject(deleteOldParams).promise();
      }
      
  
      return result.data;
    } catch (err) {
      return {
        error: true,
        msg: err?.message || 'Saving Profile Failed'
      };
    }
  };
















const generateNanoIDFilename = (file, user, mediaType) => {
    // Generate a unique identifier using nanoid
    const id = nanoid(21); // Default is 21 characters

    // Extract the file extension
    const fileExtension = file.name.split('.').pop();

    // Construct the final filename using user information and nanoid
    const customFilename = `${mediaType}-${user.firstName}-${id}.${fileExtension}`;

    return customFilename;
};

// [
//     {
//         "AllowedHeaders": [
//         "*"
//         ],
//         "AllowedMethods": [
//             "PUT",
//             "HEAD",
//             "GET"
//         ],
//         "AllowedOrigins": [
//             "*"
//         ],
//         "ExposeHeaders": []
//     }
// ]
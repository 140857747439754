import React from 'react';
import './LoadingForButton.css';

const LoadingForButton = () => {
  return (
      <div className='button-loader'>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
  );
}

export default LoadingForButton;
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Timeline.css';
import '../../Authentication/SignIn/SignIn.css';
import Layout from '../../Common/Layout';
import { getTimelineByUserCode, saveTimelineById } from '../../../service/serviceHelper';
import { formatDateForTimeline } from '../../../helper';
import { useAuth } from '../../../context/AuthContext';
import { TextField } from '@mui/material';
import Notification from '../../Common/Notification';
import LoadingForButton from '../../Common/LoadingForButton';

import { useTranslation } from 'react-i18next';
import '../../../language/i18n';
import { t } from 'i18next';

export default function Timeline() {
  const { userCode } = useParams();
  const { authState } = useAuth();
  const [loading, setLoading] = useState(true);
  const [timeline, setTimeline] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [owner, setOwner] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  const validateTimeline = () => {
    for (const entry of timeline?.dateEvent) {
      if (!entry.date || !entry.event) {
        setValidationError(t("all fields are required"));
        return false;
      }
    }
    setValidationError('');
    return true;
  };
  
  const handleCancel = () => {
    setValidationError("");
    setEditing(false);
  }

  const handleSave = async () => {
    setLoading(true);
    const saveTimeline = async () => {
      const data = {
        "dateEvent": timeline?.dateEvent,
      }
      
      const result = await saveTimelineById(timeline.id, data, authState?.user);
      if (result.error) {
        setError(t("an error occurred, please try again"));
      } else {
        setSuccess(t("saved successful"));
      }
      setLoading(false);
    }
    saveTimeline();
  }

  const handleEditing = () => {
    if (editing) {
      if (!validateTimeline()) {
        return;
      }
      // Save timeline changes here if needed
      handleSave();
    }
    setEditing(!editing);
  }

  useEffect(() => {
    setLoading(true);
    const fetchTimeline = async () => {
        const result = await getTimelineByUserCode(userCode);
        if (result.error) {
            setError(true);
        } else {
          setTimeline(
            {
              id: result?.data[0]?.attributes?.timeline?.data?.id,
              "dateEvent": result?.data[0]?.attributes?.timeline?.data?.attributes?.dateEvent || []
            } || null)
        }
        setLoading(false);
    };
    fetchTimeline();
  }, [userCode])

  useEffect(() => {
    setOwner(userCode === authState?.user?.user_code?.slug);
  }, [authState, userCode])

  return (
    <Layout userCode={userCode} loading={loading} setLoading={setLoading}>
      { owner !== true ? null : 
        <div className='button-edit'>
          {editing && !saving ? <button onClick={handleCancel} className='timeline-add'>{t("cancel")}</button> : null}
          {saving ? <button className='timeline-add'><LoadingForButton /></button> : <button className='timeline-add' onClick={handleEditing}>{editing ? t("save") : t("edit")}</button>}
        </div>
      }

      {
        editing ? 
        <TimelineEdit 
          timeline={timeline} 
          setTimeline={setTimeline} 
          validationError={validationError} 
          setValidationError={setValidationError}
        />
        :
        <TimelineCurrent timeline={timeline} />
      }
      {error && (
        <Notification
        type='error'
        message={error}
        duration={3000}
        onClose={() => setError(null)}
        />
      )}
      
  
      {success && (
        <Notification
        type='success'
        message={success}
        duration={3000}
        onClose={() => setSuccess(null)}
        />
      )}
    </Layout>
  );
}


const TimelineEdit = ({
  timeline,
  setTimeline,
  validationError,
  setValidationError
}) => { 

  const { t } = useTranslation();
  const handleChange = (e, index) => {
  const { name, value } = e.target;
  const newTimeline = [...timeline?.dateEvent];
  newTimeline[index] = {
    ...newTimeline[index],
    [name]: value,
  };
  setTimeline({
    ...timeline,
    "dateEvent": newTimeline
  });
}

  const handleRemove = () => {
    if (timeline?.dateEvent?.length !== 0) {
      setTimeline({
        ...timeline,
        "dateEvent": timeline?.dateEvent?.splice(0, timeline?.dateEvent?.length-1)
      });
    }
  }

  const handleAdd = () => {
    setTimeline({
      ...timeline,
      "dateEvent": [
      ...timeline?.dateEvent,
      {
        'date': "",
        'event': ""
      }]
    })
  }

  return (
    <div className='timeline-edit'>
      { timeline?.dateEvent?.length > 0 ?
        timeline?.dateEvent?.map((time, index) => (
          <div key={index} className='timeline-labels'>
            <TextField 
                label={t("timeline.date")}
                type="date"
                name="date"
                value={time.date}
                onChange={(e) => handleChange(e, index)}
                required
                variant="outlined" 
                InputLabelProps={{ shrink: true }}
            />
            <TextField 
                label={t("timeline.event")}
                type="text"
                name="event"
                value={time.event}
                onChange={(e) => handleChange(e, index)}
                required
                variant="outlined" 
                InputLabelProps={{ shrink: true }}
            />
          </div>
        ))
        :
        <p className='info'>{t("timeline.addNew")}!</p>
      }
      <div className='button-edit-b'>
        <button className='timeline-add' onClick={handleRemove}>{t("remove")}</button>
        <button className='timeline-add' onClick={handleAdd}>{t("new")}</button>
      </div>

      {validationError && (
          <Notification
          type='error'
          message={validationError}
          duration={3000}
          onClose={() => setValidationError(null)}
          />
      )}
    </div>
  )
}


const TimelineCurrent = ({
  timeline,
}) => {
  const { t } = useTranslation();
  return (
    <div className='timeline'>
      {timeline?.dateEvent !== null && timeline?.dateEvent?.length >= 1 ?
        <>
          <div className='dates'>
            {timeline?.dateEvent?.map((time, index) => (
              
              <p key={index}>{formatDateForTimeline(time.date)}</p>
            ))}
          </div>
          <div className='indicator'>
            <div className='circle'></div>
            {
              timeline?.dateEvent?.length > 1 ?
              <>
                <div className='stick'></div>
                <div className='circle bottom'></div>
              </>
              :
              null
            }
          </div>
          <div className='events'>
            {timeline?.dateEvent?.map((time, index) => (
              <p key={index}>{time.event}</p>
            ))}
          </div>
        </>
        :
        <p>{t("timeline.noneAdded")}!</p>
      }
    </div>
  )
}
import React from 'react';
import './Loading.css';

const Loading = ({ height }) => {
  return (
    <div
      className={`tag-loading ${height ? 'loading-for-iframe' : ''}`}
    >
      <div className='loader'>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  );
}

export default Loading;